import React from "react";
import styled from "styled-components";
import Hero from "./hero.png";
import BottomImage from "./bottom-image.jpeg";
import Tier from "../../components/Tier";
import Flourish1 from "./flourish1.svg";
import Flourish2 from "./flourish2.svg";
import Moon from "./moon.svg";

import {
  CenterContainer,
  InfoTier,
  InfoTierHeader,
  InfoContent,
  InfoContentContainer,
  FullWidthContainer,
  LeftContainer,
} from "../../components/Containers";
import Button from "../../components/Button";

const GuideContainer = styled(CenterContainer)`
  flex-direction: column;
`;

const MoonImage = styled.img`
  margin-top: 20px;
`;

const LargeText = styled.p`
  font-size: 1.5em;
  margin: 4px;
`;

function About() {
  return (
    <>
      <Tier backgroundImage={Hero} flourish={Flourish1}>
        <LeftContainer>
          <h2>Hi, I’m Alex</h2>
          <p>
            I've always balanced a logical, data-driven approach with the wisdom
            of my "hippie heart." Over the years, I've climbed the ranks in
            industries like tech, finance, and entertainment, mastering the art
            of building success—and knowing when it’s time to move on. Now, I’m
            passionate about sharing that knowledge to help you find a path that
            truly lights up your soul.
          </p>
          <p>
            Trusting my intuition, even when the data pointed in a different
            direction and others questioned my choices, has led to some of my
            most rewarding (though not always easiest) adventures. These
            experiences have aligned me with my true purpose, and now I’m here
            to offer that same guidance through personalized readings.
          </p>
          <LargeText>intuitive career coach</LargeText>
          <LargeText>energy alignment specialist</LargeText>
          <LargeText>psychic mentor</LargeText>
          <LargeText>tarot & astrology enthusiast</LargeText>
        </LeftContainer>
      </Tier>
      <InfoTier flourish={Flourish2}>
        <InfoTierHeader>
          your intuitive guide to a fulfilling career
        </InfoTierHeader>
        <GuideContainer>
          <h2>imagine yourself...</h2>
          <InfoContentContainer>
            <InfoContent>
              <p>
                waking up excited about the work you do and the impact you make
              </p>
            </InfoContent>
            <InfoContent>
              <p>
                leaving behind the burnout and frustration of unfulfilling jobs
              </p>
            </InfoContent>
            <InfoContent>
              <p>
                owning your unique talents and confidently navigating your
                career trajectory
              </p>
            </InfoContent>
          </InfoContentContainer>
          <Button to="/coaching">LEARN MORE ABOUT COACHING</Button>
        </GuideContainer>
      </InfoTier>
      <Tier backgroundImage={BottomImage}>
        <FullWidthContainer>
          <h2>
            Your purpose-driven life awaits. Unplug the noise and tune into your
            intuition.
          </h2>
          <p>
            The world and all its demands are so loud and overwhelming we easily
            lose touch with our intuition and its power.
          </p>
          <p>
            Intuition (your hippie heart) is always there and ready to guide
            whenever we call on it.
          </p>
          <p>
            Are you ready to unleash your intuition and follow your hippie
            heart?
          </p>
          <MoonImage src={Moon} alt="moon" />
        </FullWidthContainer>
      </Tier>
    </>
  );
}

export default About;
