import React from "react";
import Tier from "../../components/Tier";
import TopImage from "./TopImage.jpg";
import MiddleImage from "./MiddleImage.png";
import BottomImage from "./BottomImage.jpeg";
import Flourish1 from "./flourish1.svg";
import Flourish2 from "./flourish2.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  LeftContainer,
  InfoTier,
  InfoTierHeader,
  CenterContainer,
  InfoContent,
  FullWidthContainer,
  RowContainer,
} from "../../components/Containers";
import LargeParagraph from "../../components/LargeParagraph";
import Moon from "./moon.svg";
import Button from "../../components/Button";

const InfoContainer = styled(CenterContainer)`
  flex-direction: column;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const CoachingInfoContent = styled(InfoContent)`
  @media only screen and (max-width: 1000px) {
    max-width: 100%;
  }
`;

const AltParagraph = styled.p`
  color: #9c9387;
  font-size: 20px;
`;

const LimitedWidthContainer = styled.div`
  max-width: 800px;
  padding-bottom: 100px;
`;

const InfoTitle = styled.p`
  font-size: 24px;
  color: #83454f;
  margin-bottom: 0;
`;

const SmallCopy = styled.p`
  font-size: 14px;
  color: #525e6c;
  margin-top: 0;
`;

const MoonImage = styled.img`
  margin-top: 20px;
`;

const CoachingButton = styled(Button)`
  margin-top: 30px;
`;

const TallPoppiesLink = styled(Link)`
  color: black;
  text-decoration: none;
`;

const Coaching = () => {
  return (
    <>
      <Tier backgroundImage={TopImage} flourish={Flourish1}>
        <LeftContainer>
          <h2>
            discover fulfillment, ignite passion, embrace purpose with intuitive
            career coaching
          </h2>
          <p>
            I'm not just a career coach, I'm your guide on an empowering journey
            to ignite your inner spark and illuminate your dream career. My 1:1
            sessions are the secret weapon you've been searching for. Forget
            cookie-cutter plans and one-size-fits-all advice. This is a journey
            guided by your unique energy, where ancient wisdom meets modern
            action.
          </p>
          <LargeParagraph>unlock your career potential</LargeParagraph>
        </LeftContainer>
      </Tier>
      <InfoTier>
        <InfoTierHeader>intuition + expertise = career clarity</InfoTierHeader>
        <InfoContainer>
          <RowContainer>
            <CoachingInfoContent>
              <h2>tap into your inner compass</h2>
              <p>
                Through personalized coaching, we'll untangle hidden blockages,
                and dismantle self-limiting beliefs that hold you back.
              </p>
            </CoachingInfoContent>
            <CoachingInfoContent>
              <h2>embrace the whisper of your intuition</h2>
              <p>
                Tarot, pendulums, astrology, and Human Design become potent
                tools to illuminate your path, not predict it. You'll learn to
                harness your own intuitive whispers for powerful
                decision-making.
              </p>
            </CoachingInfoContent>
            <CoachingInfoContent>
              <h2>chart a course that sings to your soul</h2>
              <p>
                Forget "shoulds" and "supposed-tos." We'll design actionable
                steps aligned with your authentic passions and purpose.
              </p>
            </CoachingInfoContent>
            <CoachingInfoContent>
              <h2>stay connected between sessions</h2>
              <p>
                Need a quick energy boost or a reason to celebrate? My inbox is
                always open for cheerleading, check-ins, and personalized
                resource recommendations like mind-blowing meditations and
                soul-stirring reads.
              </p>
            </CoachingInfoContent>
          </RowContainer>
          <CoachingButton
            target="_blank"
            to="https://app.paperbell.com/checkout/packages/76357"
          >
            LET'S GET STARTED
          </CoachingButton>
        </InfoContainer>
      </InfoTier>
      <Tier
        backgroundImage={MiddleImage}
        flourish={Flourish2}
        centerBackground={true}
      >
        <LimitedWidthContainer>
          <h2>
            breathe deep, dream big: unlocking your career path with your inner
            compass
          </h2>
          <AltParagraph>
            Working with me as your intuitive career guide isn't just about
            landing that dream job (though, oh yes, we'll get there!). It's
            about a whole-hearted transformation, igniting your inner spark and
            aligning your work with your deepest purpose.  Here's how we'll
            co-create your professional renaissance:
          </AltParagraph>
          <InfoTitle>awaken intuition</InfoTitle>
          <SmallCopy>
            Say goodbye to guesswork and tap into the wellspring of wisdom
            within.
          </SmallCopy>
          <InfoTitle>embrace worth</InfoTitle>
          <SmallCopy>
            Shed the imposter syndrome and step into your power.
          </SmallCopy>
          <InfoTitle>banish burnout</InfoTitle>
          <SmallCopy>
            Ditch the endless hustle and reclaim your energy.
          </SmallCopy>
          <InfoTitle>illuminate purpose</InfoTitle>
          <SmallCopy>
            Forget chasing career ladders; let's climb your own mountain of
            meaning.
          </SmallCopy>
          <InfoTitle>unfurl authentic leadership</InfoTitle>
          <SmallCopy>
            Step into your natural leadership, radiating influence with genuine
            strength and compassion.
          </SmallCopy>
          <InfoTitle>master boundaries</InfoTitle>
          <SmallCopy>Learn to say "no" with grace and power.</SmallCopy>
          <InfoTitle>crack clarity open</InfoTitle>
          <SmallCopy>
            Feeling stuck in a career rut? We'll break free from limiting
            beliefs and release the fear of the unknown.
          </SmallCopy>
        </LimitedWidthContainer>
      </Tier>
      <Tier backgroundImage={BottomImage}>
        <FullWidthContainer>
          <h2>this isn't career coaching, it's a soul-awakening adventure</h2>
          <p>
            The world and all its demands are so loud and overwhelming we easily
            lose touch with our intuition and its power.
          </p>
          <p>
            Intuition (your hippie heart) is always there and ready to guide
            whenever we call on it.
          </p>
          <p>
            Are you ready to unleash your intuition, follow your hippie heart
            and ignite your career?
          </p>
          <MoonImage src={Moon} alt="moon" />
        </FullWidthContainer>
      </Tier>
    </>
  );
};

export default Coaching;
