import React from "react";
import Tier from "../../components/Tier";
import TopImage from "./TopImage.jpg";
import MiddleImage from "./MiddleImage.png";
import BottomImage from "./BottomImage.jpeg";
import Flourish1 from "./flourish1.svg";
import Flourish2 from "./flourish2.svg";
import styled from "styled-components";
import { InfoContentContainer } from "../../components/Containers";
import {
  LeftContainer,
  InfoTier,
  InfoTierHeader,
  CenterContainer,
  InfoContent,
  FullWidthContainer,
  RowContainer,
} from "../../components/Containers";
import LargeParagraph from "../../components/LargeParagraph";
import Moon from "./moon.svg";
import Button from "../../components/Button";

const InfoContainer = styled(CenterContainer)`
  flex-direction: column;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const GuideContainer = styled(CenterContainer)`
  flex-direction: column;
`;

const TestimonialsContainer = styled(GuideContainer)`
  margin-top: 25px;
  h2 {
    color: #bfa598;
  }
`;

const ReadingsInfoContentWrapper = styled(InfoContent)`
  @media only screen and (max-width: 1000px) {
    max-width: 100%;
  }
`;

const AltParagraph = styled.p`
  color: #9c9387;
  font-size: 20px;
`;

const LimitedWidthContainer = styled.div`
  max-width: 800px;
  padding-bottom: 100px;
`;

const InfoTitle = styled.p`
  font-size: 24px;
  color: #83454f;
  margin-bottom: 0;
`;

const SmallCopy = styled.p`
  font-size: 14px;
  color: #525e6c;
  margin-top: 0;
`;

const MoonImage = styled.img`
  margin-top: 20px;
`;

const ReadingsButton = styled(Button)`
  margin-top: 30px;
`;

const CopyContainer = styled.p`
  max-width: 900px;
  padding: 8px;
`;

const CopyContainerInfo = styled(CopyContainer)`
  color: white;
`;

const Readings = () => {
  return (
    <>
      <Tier backgroundImage={TopImage} flourish={Flourish1}>
        <LeftContainer>
          <h2>Hi, I’m Alex Your Intuitive Guide</h2>
          <p>
            My readings aren't about predicting the future; they're about
            empowering you to create the future you desire.
          </p>
          <p>
            Through a blend of intuition, tarot, astrology, and other tools,
            we'll tap into your soul's wisdom to gain clarity, navigate
            challenges, and uncover hidden opportunities. This is a sacred space
            for you to explore any area of your life where you're seeking
            guidance or a deeper understanding. Together, we'll illuminate your
            path and awaken the confidence to take your next steps.
          </p>
        </LeftContainer>
      </Tier>
      <InfoTier>
        <InfoTierHeader>
          personalized readings for clarity and direction
          <CopyContainer>
            There’s something truly exhilarating about witnessing people like
            you tap into their innate power and watch their visions come to
            life. For me, this isn’t just a service—it’s a calling, one I
            embraced fully after my own transformative journey.
          </CopyContainer>
        </InfoTierHeader>
        <GuideContainer>
          <h2>imagine</h2>
          <InfoContentContainer>
            <InfoContent>
              <p>waking up to a life you love, infused with purpose and joy.</p>
            </InfoContent>
            <InfoContent>
              <p>
                shifting away from confusion and uncertainty towards a clear,
                fulfilling path.
              </p>
            </InfoContent>
            <InfoContent>
              <p>
                harnessing your unique gifts and confidently making decisions
                that align with your deepest desires.
              </p>
            </InfoContent>
          </InfoContentContainer>
          <CopyContainerInfo>
            Readings are designed to address the unique challenges and questions
            you may face in any aspect of life. Whether you're seeking clarity,
            direction, or feel blocked, my readings provide insightful guidance
            to help you align with your highest potential.
          </CopyContainerInfo>
          <Button to="https://paperbell.me/hippieheart">
            LET'S GET STARTED
          </Button>
        </GuideContainer>
      </InfoTier>
      <Tier
        backgroundImage={MiddleImage}
        flourish={Flourish2}
        centerBackground={true}
      >
        <LimitedWidthContainer>
          <h2>
            intuitive readings: empowering clarity, purpose, and transformation
          </h2>
          <AltParagraph>
            Each reading session is a collaborative journey into your soul’s
            wisdom, personal energy, and the universal influences surrounding
            you. By integrating intuitive practices with tarot and astrological
            insights, I provide a comprehensive view that can help illuminate
            your path forward. Whether it's career, personal growth,
            relationships or spiritual development, these readings are tailored
            to explore the depths of your questions and provide actionable
            wisdom.
          </AltParagraph>
          <h2>benfits of a reading:</h2>
          <InfoTitle>clarity and insight:</InfoTitle>
          <SmallCopy>
            Uncover the underlying causes of feeling stuck and gain clear
            insights into your life’s dynamics.
          </SmallCopy>
          <InfoTitle>block removal</InfoTitle>
          <SmallCopy>
            We’ll discover any limiting beliefs or blocks standing in your way
            and remove those.
          </SmallCopy>
          <InfoTitle>personal growth</InfoTitle>
          <SmallCopy>
            Learn about your inherent strengths and how to harness them to
            enhance your personal journey.
          </SmallCopy>
          <InfoTitle>spiritual alignment</InfoTitle>
          <SmallCopy>
            Align more closely with your spiritual path and uncover your true
            potential.
          </SmallCopy>
        </LimitedWidthContainer>
      </Tier>

      <Tier backgroundImage={BottomImage}>
        <TestimonialsContainer>
          <h2>testimonials</h2>
          <InfoContentContainer>
            <InfoContent>
              <p>
                "Alex provided the clarity and insight I needed to confidently
                move forward. Her reading was straightforward, easy to
                understand, gave me all the guidance I needed. The insights I
                gained from this reading were invaluable, and I would highly
                recommend Alex to anyone seeking guidance!"
              </p>
              <p>- Christina</p>
            </InfoContent>
            <InfoContent>
              <p>
                "What I truly appreciated from Alex’s reading was the extra
                information she provided about what I deserve and how to move
                forward, knowing how difficult this is for me. That added touch
                made a big difference."
              </p>
              <p>- Demi</p>
            </InfoContent>
            <InfoContent>
              <p>
                "I’m so grateful for the clarity Alex brought to the reading. It
                felt spot on, and her honesty and thoroughness stood out to me.
                I really appreciated how well she interpreted the cards and
                answered my questions with precision. The insights from this
                reading were incredibly helpful!"
              </p>
              <p>- Cindy</p>
            </InfoContent>
          </InfoContentContainer>
        </TestimonialsContainer>
        <FullWidthContainer>
          <h2>Ready to embark on a journey of discovery?</h2>
          <CopyContainer>
            {" "}
            Book a session today and start transforming the unknown into
            opportunities for growth and fulfillment.
          </CopyContainer>
          <MoonImage src={Moon} alt="moon" />
        </FullWidthContainer>
      </Tier>
    </>
  );
};

export default Readings;
