import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import styled from "styled-components";
import Tier from "../../components/Tier";
import BottomImage from "./BottomImage.jpeg";
import { FullWidthContainer } from "../../components/Containers";
import Moon from "./moon.svg";
import Flourish from "./flourish.svg";
import Button from "../../components/Button";

const MoonImage = styled.img`
  margin-top: 20px;
`;

const Container = styled.div`
  max-width: 715px;
  margin: 0 auto;
`;

const GetStartedButton = styled(Button)`
  background: #552d43;
  color: white;
  text-transform: none;
  width: fit-content;
  margin: 16px auto 32px auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #552d43;
  border-radius: 30px;
  padding: 30px;
  color: white;
  gap: 15px;
  margin: 15px 15px 100px 15px;
  input {
    font-size: 20px;
    min-height: 30px;
  }
  textarea {
    min-height: 300px;
  }
  textarea:last-of-type {
    min-height: 200px;
  }
  label {
    margin-top: 5px;
    margin-bottom: -2px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #552d43;
    min-height: 38px;
    border-radius: 5px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    background: white;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
  }
`;

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xvoeboyv");

  if (state.succeeded) {
    return (
      <p>Thanks for contacting Hippie Heart! We will be in touch shortly.</p>
    );
  }
  return (
    <>
      <Tier flourish={Flourish}>
        <Container>
          <h2>free 30 minute free consultation</h2>
          <p>
            Free 30 Minute consultation: Welcome to your intuitive career
            journey! Let's start with a 30-minute conversation where I’ll get to
            know your path, aspirations, and see if hippie heart aligns with
            your goals.
            <GetStartedButton
              to="https://paperbell.me/hippieheart"
              target="_blank"
            >
              LET'S GET STARTED
            </GetStartedButton>{" "}
          </p>
          <hr />
          <h2>book a 30 minute or 60 minute reading</h2>
          <p>
            My readings aren't about predicting the future; they're about
            empowering you to create the future you desire. Together, we'll
            illuminate your path and awaken the confidence to take your next
            steps.
            <GetStartedButton
              to="https://paperbell.me/hippieheart"
              target="_blank"
            >
              LET'S GET STARTED
            </GetStartedButton>{" "}
          </p>
          <hr />
          <h2>
            Want to learn more? I'd love to hear from you! Send me an email.
          </h2>
          <Form onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input id="name" type="name" name="name" required />
            <ValidationError prefix="Name" field="name" errors={state.errors} />

            <label htmlFor="email">Email</label>
            <input id="email" type="email" name="email" required />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />

            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />

            <button type="submit" disabled={state.submitting}>
              Submit
            </button>
          </Form>
        </Container>
      </Tier>
      <Tier backgroundImage={BottomImage}>
        <FullWidthContainer>
          <h2>this is just the beginning</h2>
          <p>
            Remember, our first 30 minute career coaching consultation is just a
            starting point. Our collaboration will be a dynamic process,
            evolving as we uncover your unique path.
          </p>
          <p>
            I'm here to listen, guide, and empower you as you awaken your inner
            compass and navigate towards your soul-aligned life.
          </p>
          <MoonImage src={Moon} alt="moon" />
        </FullWidthContainer>
      </Tier>
    </>
  );
};

export default ContactForm;
